@import "~@/assets/scss/abstracts.scss";

.layer-popup {
	&-terms {
		padding-left: clamp(15px, 4vw, 140px);
		padding-right: clamp(15px, 4vw, 140px);
		text-align: left;
		font-size: 14px;
		line-height: 1.5;
		.popup-header .btn-close {
			right: 0;
		}
		.popup-buttons {
			gap: 15px;
			align-items: center;
			padding: 1.5em 0;
			@include mobile {
				flex-wrap: wrap;
			}
			.desc {
				font-size: 16px;
				flex: 1 1;
				@include mobile {
					flex: inherit;
					width: 100%;
				}
			}
			.button-default {
				height: 40px;
				border: 1px solid #645f5b;
				flex: 0 0 100px;
				@include transition(opacity, 0.5s);
				&:hover,
				&:focus {
					background-color: #444;
				}
				&.is-light {
					&:hover,
					&:focus {
						border-color: #111;
						background-color: transparent;
					}
				}
				&.is-disabled {
					opacity: 0.3;
					pointer-events: none;
				}
			}
		}
	}
	.popup-body {
		padding: 0;
	}
	.terms-wrap {
		padding: 2em;
		overflow-y: auto;
		max-height: calc(100vh - 300px);
		border: 1px solid #e1e1e1;
		background-color: #fff;
		@include tablet {
			max-height: calc(100vh - 220px);
		}
		p {
			margin-bottom: 1em;
		}
	}
	.terms-section {
		margin-bottom: 2em;
		&-title {
			margin-bottom: 0.5em;
			font-size: 18px;
			font-weight: 700;
		}
	}
}
